<template>
  <!-- TODO: Make sure Back Vintages don't have a checkout  -->
  <div>
    <div class="pfolio-section px-4 bg-white h-full max-h-35v text-center">
      <h2 class="mt-10 md:mb-10 uppercase">{{portfolio.title}}</h2>
      <div class="buttons hidden lg:flex flex-wrap md:flew-nowrap w-full justify-around my-10">
        <div class="white-banner  lg:scale-75" style="position: relative;">
          <a @click="tierCheckbox('Regional-subsection')">
            <img class="white-banner" src='/assets/svg/regional.svg'>
            <div class="text-white w-full text-27 button-style">Regional
            </div>
          </a>
        </div>
        <div class="white-banner  lg:scale-75"  style="position: relative;">
          <a @click="tierCheckbox('Dolomite-subsection')">
            <img class="white-banner" src='/assets/svg/dolomite.svg'>
            <div class="text-white w-full text-27 button-style">Dolomite
            </div>
          </a>
        </div>
        <div class="white-banner  lg:scale-75" style="position: relative;">
          <a @click="tierCheckbox('Estate-subsection')">
            <img class=" white-banner" src='/assets/svg/estate.svg'>
            <div class="text-white w-full text-27 button-style">Estate
            </div>
          </a>
        </div>
        <div class="white-banner  lg:scale-75" style="position: relative;">
          <a @click="tierCheckbox('Proprietary-subsection')">
            <img class=" white-banner" src='/assets/svg/proprietary.svg'>
            <div class="text-white w-full text-27 button-style">Proprietary
            </div>
          </a>
        </div>
      </div>


      <div v-if="activeWineTier.length >= 0 && tierSubsection === 'Dolomite' " >
        <h3 class="uppercase text-font-h3 mb-3">{{tierSubsection}}</h3>
        <p class="md:mb-10 text-font-h3 md:w-4/6 w-5/6 mx-auto "><span id="portfolio" v-html="dolomite"></span></p>
      </div>
       <div v-else-if="activeWineTier.length >= 0 && tierSubsection === 'Regional' " >
         <h3 class="uppercase text-font-h3 mb-3">{{tierSubsection}}</h3>
         <p class="md:mb-10 text-font-h3 md:w-4/6 w-5/6 mx-auto "><span id="portfolio" v-html="regional"></span></p>
      </div>
 <div v-else-if="activeWineTier.length >= 0  && tierSubsection === 'Estate' " >
   <h3 class="uppercase text-font-h3 mb-3">{{tierSubsection}}</h3>
        <p class="md:mb-10 text-font-h3 md:w-4/6 w-5/6 mx-auto "><span id="portfolio" v-html="estate"></span></p>
      </div>
 <div v-else-if="activeWineTier.length >= 0  && tierSubsection === 'Proprietary' " >
   <h3 class="uppercase text-font-h3 mb-3">{{tierSubsection}}</h3>
   <p class="md:mb-10 text-font-h3 md:w-4/6 w-5/6 mx-auto "><span id="portfolio" v-html="proprietary"></span></p>
      </div>
      <div v-else>
      <p class="md:my-10 text-font-h3 md:w-4/6 w-5/6 mx-auto"><span id="portfolio" v-html="paragraph"></span></p>
      <div class="md:my-10 text-font-h3"><div id="vineyard" v-html="vineyard"></div></div>
      </div>



    </div>


    <!-- <div v-for="wine of this.store">
    {{wine.title}}
    </div> -->
    <div class="wines-container bg-grey-light pt-2.5 text-font-title pb-10">

      <div v-if="currentWine" class="ml-8 md:!ml-0 md:pl-20 breadcrumbs">
        <div v-if="!activeType && activeWineTier < 1">
          <span><a href="/wine">OUR WINES </a> > All Wines</span>
        </div>
        <div v-else-if="activeWine.length >=1">
          <span class="uppercase"> <a href="/wine">OUR WINES </a> > {{activeType}} > <a
              v-for="(wine,index of activeWine">{{wine}} <span v-if="index != activeWine.length - 1"> / </span> </a>
          </span>
        </div>
        <div v-else-if="activeWineTier.length >= 1">
          <span class="uppercase">
            <a href="/wine">OUR WINES </a> > <a v-for="(tier,index of activeWineTier">{{tier}} <span
                v-if="index != activeWineTier.length - 1"> / </span> </a> </span>
        </div>
        <div v-else-if="activeType!==''">
          <span class="uppercase"><a href="/wine">OUR WINES </a> > {{activeType}}</span>
        </div>
        <a class="block lg:hidden text-font-menu" @click="filterMenu = true"> <span class="flex items-center"> <span
              class="pr-2.5">Filter </span> <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20"
              fill="#72624e">
              <path fill-rule="evenodd"
                d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                clip-rule="evenodd" />
            </svg> </span> </a>
      </div>
      <div v-else-if="!currentWine" class="pl-20">
        <div v-if="!activeType && activeWineTier < 1">
          <span><a href="/wine">OUR WINES </a> > Back Vintages</span>
        </div>
        <div v-else-if="activeWine.length >=1">
          <span class="uppercase"> <a href="/wine">OUR WINES </a> > {{activeType}} > <a
              v-for="(wine,index of activeWine">{{wine}} <span v-if="index != activeWine.length - 1"> / </span> </a>
          </span>
        </div>
        <div v-else-if="activeWineTier.length >= 1">
          <span class="uppercase">
            <a href="/wine">OUR WINES </a> > <a v-for="(tier,index of activeWineTier">{{tier}} <span
                v-if="index != activeWineTier.length - 1"> / </span> </a> </span>
        </div>
        <div v-else-if="activeType!==''">
          <span class="uppercase"><a href="/wine">OUR WINES </a> > {{activeType}}</span>
        </div>
        <a class="block lg:hidden text-font-menu" @click="filterMenu = true"> <span class="flex items-center"> <span
              class="pr-2.5">Filter </span> <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20"
              fill="#72624e">
              <path fill-rule="evenodd"
                d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                clip-rule="evenodd" />
            </svg> </span> </a>
      </div>


      <div class="flex flex-no-wrap">
        <div class="shop-nav w-2/6 pl-20 mt-5 hidden lg:block">
          <div class="uppercase filter-by w-1/2 flex flex-no-wrap text-font-h3">
            <h4>Filter By</h4>
            <input v-if="activeType || activeWine.length > 1 || activeWineTier.length >= 1" @click="reset()"
              class="button ml-10 text-center w-1/2 bg-font-blue text-white" value="Reset">
          </div>

          <div class="uppercase font-semibold text-font-blue my-5 type">
            <h3>TYPE</h3>
          </div>

          <!-- <Checkbox v-for="(box, id) in wineType" :key="id" :value="box.check"
          :checked="box.checked" :disabled="box.disabled" @change="selection(box)">{{ box }}</Checkbox> -->
          <div class="checkbox" v-for="(category,index) of wineType">
            <!-- <input type="checkbox" v-on:click="selection(category)" id="checkbox">
          <label for="checkbox">{{ category }}</label> -->
            <Checkbox ref="parent" value="category" :key="index" @change="selection(category)" color="#4a86a2">
              {{category}}
            </Checkbox>


            <div class="checkbox" v-if="category === 'White'">
              <div id="white" class="ml-10" v-show="isActive" v-for="wineVarieties of wineTypeWhite">
                <Checkbox ref="child" value="wineVarieties" @change="selectionChild(wineVarieties)" color="#4a86a2">
                  {{ wineVarieties
                  }}
                </Checkbox>
                <!-- <input type="checkbox" v-on:click="selectionChild(wineVarieties)" id="checkbox-white">
              <label for="checkbox">{{ wineVarieties }}</label> -->
              </div>
            </div>
            <div class="checkbox" v-else-if="category === 'Red'">
              <div id="red" class="ml-10" v-show="isActiveRed" v-for="wineVarieties of wineTypeRed">
                <!-- <input type="checkbox" v-on:click="selectionChild(wineVarieties)" id="checkbox-red">
              <label for="checkbox">{{ wineVarieties }}</label> -->
                <Checkbox ref="child" value="wineVarieties" @change="selectionChild(wineVarieties)" color="#4a86a2">
                  {{ wineVarieties
                  }}
                </Checkbox>
              </div>
            </div>
          </div>
          <div class="uppercase font-semibold text-font-blue my-5 Tier">
            <h3>TIER</h3>
          </div>
          <div class="checkbox" v-for="(tier,index) of winetiers" >
            <Checkbox ref="tier" value="tier" :key="index" @change="selection(tier)" color="#4a86a2">
              {{tier}}
            </Checkbox>
          </div>
          <div class="uppercase font-semibold text-font-blue my-5 Tier cursor-pointer">
            <h3 v-if='currentWine' @click="currentWine = !currentWine; specialOffers = False; rewards = False;">Back
              Vintages</h3>
            <h3 v-else-if='!currentWine' @click="currentWine = !currentWine; specialOffers = False; rewards = False;">
              Current Vintages
            </h3>
          </div>
<!--          <div class="uppercase font-semibold text-font-blue my-5 Tier cursor-pointer">-->
<!--            <h3 @click="specialOffers = !specialOffers; rewards = False;">Gifts</h3>-->
<!--          </div>-->
<!--          <div class="uppercase font-semibold text-font-blue my-5 Tier cursor-pointer">-->
<!--            <h3 @click="rewards = !rewards; specialOffers = False;">Rewards</h3>-->
<!--          </div>-->

          <div class="uppercase font-semibold text-font-blue my-5 Tier cursor-pointer">
            <a href="/collection/rewards">
              <h3 >Redeem Rewards</h3>
            </a>
          </div>
          <div class="uppercase font-semibold text-font-blue my-5 Tier cursor-pointer">
            <a href="/collection/gift-cards-">
            <h3 >Gift Cards</h3>
            </a>
          </div>
          <div class="uppercase font-semibold text-font-blue my-5 Tier cursor-pointer">
            <a href="/collection/events-">
              <h3 >Events</h3>
            </a>
          </div>
          <div class="uppercase font-semibold text-font-blue my-5 Tier cursor-pointer">
            <a href="/collection/bundles-">
            <h3 >Bundles</h3>
            </a>
          </div>
<!--          <div class="uppercase font-semibold text-font-blue my-5 Tier cursor-pointer">-->
<!--            <a href="/collection/white" >-->
<!--            <h3 >Merchandise</h3>-->
<!--            </a>-->
<!--          </div>-->

        </div>


        <div v-if='!specialOffers && !rewards' class="wines-section w-full lg:w-4/6 ">
          <div v-for="tier in winetiers" v-if="titleDisplay(tier)" class="flex flex-wrap">

            <div :id="tier.toLowerCase()" class="uppercase font-semibold text-font-blue my-5 w-full text-27">
              <h3 class="w-full font-Palatino"> {{ tier }} </h3>
            </div>

            <div class="w-2/4 lg:w-1/4 mt-10 mb-10 flex justify-center relative text-white" @mouseover="hovered = index"
              @mouseleave="hovered = false" v-for="(wine, index) in display" v-if="wine.field_wineTier === tier"
              :class="determineClass(wine.field_wineCategoryVariety, wine.field_wineStyleSparkling, wine.field_sku, wine.field_bottleVolume, wine.title)">


              <!-- {{wine.title}}
    {{wine.field_techImage}} ew -->
              <div v-if="currentWine">
                <transition name=" fade">
                  <div class="absolute hover__class p-2 text-center" v-if="hovered === index">
                    <div class="p-6" style="border: 1px solid white; position: static; display: flex; flex-wrap: wrap; justify-content: center;">
<!--                      TODO: REPLACE THE Column Suffix-->
                      <p class="w-full">{{wine.title.toUpperCase()}}<br>
                        ${{ wine.field_ontarioPrice }}  ({{ wine.field_bottleVolume}})</p>
                      <div class="white-banner mt-5" style="position: relative;">
                        <a :href="'/individual-wine?wine=' + wine.slug + '&vintage=cur'">
                          <img class="white-banner" src='/assets/svg/banner.svg'>
                          <div class="text-white text-xs w-full button-style"> LEARN MORE / BUY
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </transition>
                <div class="flex items-center justify-center">
                  <div v-if="promotionDisplay(wine) && hovered !== index" class="buttons flex absolute w-full justify-center items-center my-4">
                    <div class="relative white-banner mr-4">

                      <img v-if="wine.field_promoType_qkkxzmgw == 'sale'" class="white-banner" src='/assets/svg/sale.svg'>
                      <img v-else-if="wine.field_promoType_qkkxzmgw == 'newVintage'" class="white-banner" src='/assets/svg/newVintage.svg'>
                      <img v-else class="white-banner" src='/assets/svg/member-prerelease.svg'>
                      <div class="text-white w-full button-style">{{wine.field_promoMessage_xyvkrwsy}} </div>
                    </div>
                  </div>

                  <img v-bind:src="wine.field_techImage" class="wine-bottle" />

                </div>

              </div>
              <div v-if="!currentWine">
                <transition name=" fade">
                  <div class="absolute hover__class p-2 text-center" v-if="hovered === index">
                    <div class="p-6"
                      style="border: 1px solid white; position: static; display: flex; flex-wrap: wrap; justify-content: center;">
                      <p class="w-full">{{wine.title.toUpperCase()}}</p>
                      <div class="white-banner mt-5" style="position: relative;">
                        <a :href="'/individual-wine?wine=' + wine.slug + '&vintage=back'">
                          <img class="white-banner" src='/assets/svg/banner.svg'>
                          <div class="text-white text-xs w-full button-style"> LEARN MORE
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </transition>
                <div>

                  <img v-bind:src="wine.field_techImage" class="wine-bottle" />

                </div>

              </div>
            </div>
          </div>
        </div>

        <div v-if='specialOffers' class="wines-section w-full lg:w-4/6">
          <div class="flex flex-wrap">
            <div class="uppercase font-semibold text-font-blue my-5 w-full text-27">
              <h3 class="w-full"> Gifts </h3>
            </div>

            <div class="w-2/4 lg:w-1/4 mt-10 mb-10 flex justify-center relative text-white" @mouseover="hovered = index"
              @mouseleave="hovered = false" v-for="(wine, index) in specials" v-if="specials.length">

              <div v-for="item in matchWine(wine.wineItemNumber)" v-if="item && noMatch(wine.wineItemNumber)">
                <transition name=" fade">
                  <div class="absolute hover__class p-2 text-center" v-if="hovered === index">
                    <div class="p-6"
                      style="border: 1px solid white; position: static; display: flex; flex-wrap: wrap; justify-content: center;">
                      <p class="w-full">{{wine.title.toUpperCase()}}<br>
                        ${{ item.field_ontarioPrice }} ({{ wine.wineVolume}})</p>
                      <div class="white-banner mt-5" style="position: relative;">
                        <a :href="'/individual-wine?wine=' + item.slug ">
                          <img class="white-banner" src='/assets/svg/banner.svg'>
                          <div class="text-white w-full button-style"> LEARN MORE/BUY
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </transition>



                <div>

                  <img v-bind:src="item.field_techImage" class="wine-bottle" />

                </div>

              </div>
              <!-- TODO: Make Sure this links to a buy page with no information  -->
              <div v-if="!noMatch(wine.wineItemNumber)">
                <transition name=" fade">
                  <div class="absolute hover__class p-2 text-center" v-if="hovered === index">
                    <div class="p-6"
                      style="border: 1px solid white; position: static; display: flex; flex-wrap: wrap; justify-content: center;">
                      <p v-if="wine.wineVolume > 0" class="w-full">{{wine.title.toUpperCase()}}<br>
                        ${{ wine.winePrice }} ({{ wine.wineVolume}})</p>
                      <p v-else class="w-full">{{wine.title.toUpperCase()}}<br>
                        ${{ wine.winePrice }}</p>
                      <div class="white-banner mt-5" style="position: relative;">
                        <a :href="'/individual-wine?wine='">
                          <img class="white-banner" src='/assets/svg/banner.svg'>
                          <div class="text-white w-full button-style"> LEARN MORE/BUY
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </transition>


                <!-- TODO: Make the photos dynamic based off of the sku or title -->
                <div>

                  <img v-if="wine.wineImage[0]" v-bind:src="wine.wineImage[0].url" class="wine-bottle" />
                  <img v-else="!wine.wineImage[0]"
                    src="https://cave-redesign.nitro/assets/techPhotos/2019-cabernet-franc-dolomite-.png"
                    class="wine-bottle" />

                </div>

              </div>

            </div>
          </div>
        </div>
        <div v-if='rewards' class="wines-section w-full lg:w-4/6">
          <div class="flex flex-wrap">
            <div class="uppercase font-semibold text-font-blue my-5 w-full text-27">
              <h3 class="w-full"> Rewards </h3>
            </div>

            <div class="w-2/4 lg:w-1/3 mt-10 mb-10 flex justify-center relative text-white" v-bind:class="wine.wineItemNumber" @mouseover="hovered = index"
              @mouseleave="hovered = false" v-for="(wine, index) in rewardsProducts.products"
              v-if="rewardsProducts.products">


              <transition name=" fade">
                <div class="absolute hover__class p-2 text-center" v-if="hovered === index">
                  <div class="p-6"
                    style="border: 1px solid white; position: static; display: flex; flex-wrap: wrap; justify-content: center;">
                    <p class="w-full">{{wine.title.toUpperCase()}}<br>

                      {{ wine.points }} Points</p>
                    <div class="white-banner mt-5" style="position: relative;">
                      <a :href="'/individual-reward?r=' + wine.slug">
                        <img class="white-banner" src='/assets/svg/banner.svg'>
                        <div class="text-white w-full button-style"> LEARN MORE/BUY
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </transition>



              <div>

                <img v-bind:src="wine.wineImage[0].sized" class="wine-bottle" />

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    MOBILE MENU FOR FILTERING-->
    <div v-if="filterMenu" class="fixed block lg:hidden bg-grey-light h-screen px-4"
      style="z-index: 1000; top: 0; width:330px;">
      <div class="flex flex-no-wrap mt-4">
        <div class="uppercase filter-by w-1/2">
          <h4>Filter By</h4>
          <div>
            <a v-if="activeType || activeWine.length > 1 || activeWineTier.length >= 1" @click="reset()"
              class="text-center bg-font-blue text-white">Reset</a>
          </div>
        </div>
        <div class="w-1/2 text-right">
          <a class="cursor-pointer" @click="filterMenu = false">Close</a>
        </div>
      </div>

      <div class="uppercase font-semibold text-font-blue my-5 type">
        <h3>TYPE</h3>
      </div>

      <!-- <Checkbox v-for="(box, id) in wineType" :key="id" :value="box.check"
        :checked="box.checked" :disabled="box.disabled" @change="selection(box)">{{ box }}</Checkbox> -->
      <div class="checkbox" v-for="(category,index) of wineType">
        <!-- <input type="checkbox" v-on:click="selection(category)" id="checkbox">
        <label for="checkbox">{{ category }}</label> -->
        <Checkbox ref="parent" value="category" :key="index" @change="selection(category)" color="#4a86a2">
          {{category}}
        </Checkbox>


        <div class="checkbox" v-if="category === 'White'">
          <div id="white" class="ml-10" v-show="isActive" v-for="wineVarieties of wineTypeWhite">
            <Checkbox ref="child" value="wineVarieties" @change="selectionChild(wineVarieties)" color="#4a86a2">
              {{ wineVarieties
              }}
            </Checkbox>
            <!-- <input type="checkbox" v-on:click="selectionChild(wineVarieties)" id="checkbox-white">
            <label for="checkbox">{{ wineVarieties }}</label> -->
          </div>
        </div>
        <div class="checkbox" v-else-if="category === 'Red'">
          <div id="red" class="ml-10" v-show="isActiveRed" v-for="wineVarieties of wineTypeRed">
            <!-- <input type="checkbox" v-on:click="selectionChild(wineVarieties)" id="checkbox-red">
            <label for="checkbox">{{ wineVarieties }}</label> -->
            <Checkbox ref="child" value="wineVarieties" @change="selectionChild(wineVarieties)" color="#4a86a2">
              {{ wineVarieties
              }}
            </Checkbox>
          </div>
        </div>
      </div>
      <div class="uppercase font-semibold text-font-blue my-5 Tier">
        <h3>TIER</h3>
      </div>
      <div class="checkbox" v-for="(tier,index) of winetiers">
        <Checkbox ref="tier" value="tier" :key="index" @change="selection(tier)" color="#4a86a2">
          {{tier}}
        </Checkbox>
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from 'vue-material-checkbox'
import gql from 'graphql-tag'
export default {
  name: "OurWinesComponent",
  props: ['store', 'tech', 'portfolio', 'paragraph', 'vineyard','logged', 'regional', 'dolomite', 'estate' , 'proprietary'],
  components: { Checkbox },
  apollo: {
  products: { 
    query: gql`query GetProducts {
        products(type: "wine") {
          title
          id
            ... on wine_Product {
              id
              wineCategory
              wineCode
              wineVolume
              winePrice
              wineItemNumber
              wineQuantity
              itemNumberMatch
              wineImage{
                url
              }
            }
          variants {
            stock
            price
            id
          }
        }
  }`,
      update: data => data,
},
rewardsProducts: {
    query: gql`query GetProducts {
        products(type: "rewards") {
          title
          id
          slug
            ... on rewards_Product {
              id
              wineCategory
              wineCode
              points
              wineItemNumber
              wineQuantity
              description
              wineImage{
                url
                sized: url @transform(width: 300, height: 300)
              }
            
            }
          variants {
            stock
            price
            id
          }
        }
  }`,
  update: data => data,
    },
    promos: {
    query: gql`query GetEntries {
       entries(section:"promo") {
    ... on promo_default_Entry {
      id
      promoMessage
      promoSku
      promoType(label: false)
      displayUntil
    }
  }
  }`,
  update: data => data,
    },
  //   promotions: {
  //   query: gql`query GetEntries {
  //        entries(type:"promotions") {
  //   ... on promotions_promotions_Entry {
  //
  //        id
  //        promotions {
  //       ... on promotions_promotion_BlockType {
  //         id
  //         color
  //         message
  //         displayUntil
  //         wine
  //       }
  //     }
  //     }
  //
  //   }
  // }`,
  // update: data => data,
  //   },
  //   promos: {
  //   query: gql`query GetEntries {
  //        entries(section:"promo") {
  //         ... on promo_default_Entry {
  //     id
  //     promoMessage
  //     promoSku
  //     promoType
  //        }
  //        }
  //
  // }`,
  // update: data => data,
  //   },
 },
 
  data() {
    
    return {
      selected: false,

      redSpecialCase: false,
  

      wineType: ['White', 'Rosé', 'Red', 'Sparkling', 'Sweet'],

      wineTypeWhite: ['Riesling', 'Chardonnay', 'Pinot Gris', 'Gewürztraminer',],

      wineTypeRed: ['Gamay', 'Pinot Noir', 'Cabernet Franc', 'La Penna',],
      wineTypeFull: ['Riesling', 'Chardonnay', 'Pinot Gris', 'Gewürztraminer', 'Pinot Noir', 'Cabernet Franc', 'Gamay', 'La Penna',],


      winetiers: ['Regional','Dolomite','Estate','Proprietary'],
      
      storeWine: { 

      },

      techWine: {

      }, 

      role: '',
    
      activeWine: [],
      
      activeWineTier:[],

      activeType: '',

      tierSubsection: '', // can be Regional Dolomite Estate Proprietary using to change the tier messages at the top
      
     
      isActive: false,
      isActiveRed: false,

      hovered: null,
      filterMenu: false,
      currentWine: true, //1 is current 0 is backvintage
      products: {products:[]},
      rewardsProducts: { rewardsProducts:[]},
      specialOffers: false,
      rewards: false,

    }},
  computed: {
    display: function () {
      var self = this
      return this.tech.filter(function (type) {

        if (self.currentWine) {

          if (type.field_currentVintage_wfeydybl !== "1") {
            return;
          }

          if (self.activeType && self.activeWine.length === 0) {
            console.log('first')
            return type.field_winesCategory === self.activeType;

          } else if (self.activeWine.length >= 1 && self.activeWineTier.length < 1) {
            if (self.activeType === 'White') {
              let whiteArray = [];
              for (let element of self.activeWine) {
                if (self.wineTypeWhite.includes(element)) {
                  whiteArray.push(element);
                }
              }
              switch (whiteArray.length) {
                case 0:
                  break;
                case 1:
                  console.log('case 1')
                  return type.field_wineCategoryVariety === whiteArray[0];
                case 2:
                  console.log('case 2')
                  return type.field_wineCategoryVariety === whiteArray[0] || type.field_wineCategoryVariety === whiteArray[1];
                case 3:
                  console.log('case 3')
                  return type.field_wineCategoryVariety === whiteArray[0] || type.field_wineCategoryVariety === whiteArray[1] || type.field_wineCategoryVariety === whiteArray[2];
                case 4:
                  console.log('case 4')
                  return type.field_wineCategoryVariety === whiteArray[0] || type.field_wineCategoryVariety === whiteArray[1] || type.field_wineCategoryVariety === whiteArray[2] || type.field_wineCategoryVariety === whiteArray[3];
                default:
                  console.log('default')
                  return self.tech;
              }


            } else if (self.activeType === 'Red') {
              let redArray = [];
              for (let element of self.activeWine) {
                if(element == 'La Penna'){
                  self.redSpecialCase = true;
                  continue;
                }
                if (self.wineTypeRed.includes(element)) {
                  redArray.push(element);
                }


              }
              if(!self.redSpecialCase) {
                switch (redArray.length) {
                  case 0:
                    break;
                  case 1:
                    console.log('case 1')
                    return type.field_wineCategoryVariety === redArray[0];
                  case 2:
                    console.log('case 2')
                    return type.field_wineCategoryVariety === redArray[0] || type.field_wineCategoryVariety === redArray[1];
                  case 3:
                    console.log('case 3')
                    return type.field_wineCategoryVariety === redArray[0] || type.field_wineCategoryVariety === redArray[1] || type.field_wineCategoryVariety === redArray[2];
                  case 4:
                    console.log('case 4')
                    return type.field_wineCategoryVariety === redArray[0] || type.field_wineCategoryVariety === redArray[1] || type.field_wineCategoryVariety === redArray[2] || type.field_wineCategoryVariety === redArray[3];
                  default:
                    console.log('default')
                    return self.tech;
                }
              }else{
                switch (redArray.length) {
                  case 0:
                    console.log(type)
                    type.field_wineName === `La Penna`;
                  case 1:
                    console.log('case 1')
                    return type.field_wineCategoryVariety === redArray[0] ||  type.field_wineName === `La Penna`;
                  case 2:
                    console.log('case 2')
                    return type.field_wineCategoryVariety === redArray[0] || type.field_wineCategoryVariety === redArray[1] ||  type.field_wineName === `La Penna`;
                  case 3:
                    console.log('case 3')
                    return type.field_wineCategoryVariety === redArray[0] || type.field_wineCategoryVariety === redArray[1] || type.field_wineCategoryVariety === redArray[2] ||  type.field_wineName === `La Penna`;
                  default:
                    console.log('default')
                    return self.tech;
                }
              }



            }
          } else if (self.activeWineTier.length >= 1 && self.activeType) {
            console.log('fourth')
            switch (self.activeWineTier.length) {
              case 0:
                break;
              case 1:
                return type.field_wineTier === self.activeWineTier[0] && self.activeType;
              case 2:
                console.log("case 2")
                return (type.field_wineTier === self.activeWineTier[0] && self.activeType) || (type.field_wineTier === self.activeWineTier[1] && self.activeType);
              case 3:
                console.log("case 3")
                return type.field_wineTier === self.activeWineTier[0] || type.field_wineTier === self.activeWineTier[1] || type.field_wineTier === self.activeWineTier[2];
              case 4:
                console.log("case 4")
                return type.field_wineTier === self.activeWineTier[0] || type.field_wineTier === self.activeWineTier[2] || type.field_wineTier === self.activeWineTier[1] || type.field_wineTier === self.activeWineTier[3];
            }
          } else if (self.activeWineTier.length >= 1 && self.activeWine.length < 1) {
            console.log('third')
            switch (self.activeWineTier.length) {
              case 0:
                break;
              case 1:
                return type.field_wineTier === self.activeWineTier[0];
              case 2:
                console.log("case 2")
                return type.field_wineTier === self.activeWineTier[0] || type.field_wineTier === self.activeWineTier[1];
              case 3:
                console.log("case 3")
                return type.field_wineTier === self.activeWineTier[0] || type.field_wineTier === self.activeWineTier[1] || type.field_wineTier === self.activeWineTier[2];
              case 4:
                console.log("case 4")
                return type.field_wineTier === self.activeWineTier[0] || type.field_wineTier === self.activeWineTier[2] || type.field_wineTier === self.activeWineTier[1] || type.field_wineTier === self.activeWineTier[3];
            }

          } else {//DISPLAY ALL WINE
            console.log('fith')
            // console.log(type.field_currentVintage);
            // console.log(type.field_currentVintage_wfeydybl);

            return self.tech;
            // type.field_currentVintage_wfeydybl === "1";
          }
        } else if (!self.currentWine) {
          console.log('Second Condtion')
          if (type.field_currentVintage_wfeydybl !== "0") {
            return;
          }



          if (self.activeType && self.activeWine.length === 0) {
            console.log('first')
            return type.field_winesCategory === self.activeType;

          } else if (self.activeWine.length >= 1 && self.activeWineTier.length < 1) {
            if (self.activeType === 'White') {
              let whiteArray = [];
              for (let element of self.activeWine) {
                if (self.wineTypeWhite.includes(element)) {
                  whiteArray.push(element);

                }

              }
              switch (whiteArray.length) {
                case 0:
                  break;
                case 1:
                  console.log('case 1')
                  return type.field_wineCategoryVariety === whiteArray[0];
                case 2:
                  console.log('case 2')
                  return type.field_wineCategoryVariety === whiteArray[0] || type.field_wineCategoryVariety === whiteArray[1];
                case 3:
                  console.log('case 3')
                  return type.field_wineCategoryVariety === whiteArray[0] || type.field_wineCategoryVariety === whiteArray[1] || type.field_wineCategoryVariety === whiteArray[2];
                case 4:
                  console.log('case 4')
                  return type.field_wineCategoryVariety === whiteArray[0] || type.field_wineCategoryVariety === whiteArray[1] || type.field_wineCategoryVariety === whiteArray[2] || type.field_wineCategoryVariety === whiteArray[3];
                default:
                  console.log('default')
                  return self.tech;
              }


            } else if (self.activeType === 'Red') {
              let redArray = [];
              for (let element of self.activeWine) {
                if (self.wineTypeRed.includes(element)) {
                  redArray.push(element);

                }

              }
              switch (redArray.length) {
                case 0:
                  break;
                case 1:
                  console.log('case 1')
                  return type.field_wineCategoryVariety === redArray[0];
                case 2:
                  console.log('case 2')
                  return type.field_wineCategoryVariety === redArray[0] || type.field_wineCategoryVariety === redArray[1];
                case 3:
                  console.log('case 3')
                  return type.field_wineCategoryVariety === redArray[0] || type.field_wineCategoryVariety === redArray[1] || type.field_wineCategoryVariety === redArray[2];
                case 4:
                  console.log('case 4')
                  return type.field_wineCategoryVariety === redArray[0] || type.field_wineCategoryVariety === redArray[1] || type.field_wineCategoryVariety === redArray[2] || type.field_wineCategoryVariety === redArray[3];
                default:
                  console.log('default')
                  return self.tech;
              }


            }
          } else if (self.activeWineTier.length >= 1 && self.activeType) {
            console.log('fourth')
            // console.log(self.acti)
            switch (self.activeWineTier.length) {
              case 0:
                break;
              case 1:
                return type.field_wineTier === self.activeWineTier[0] && self.activeType;
              case 2:
                console.log("case 2")
                return (type.field_wineTier === self.activeWineTier[0] && self.activeType) || (type.field_wineTier === self.activeWineTier[1] && self.activeType);
              case 3:
                console.log("case 3")
                return type.field_wineTier === self.activeWineTier[0] || type.field_wineTier === self.activeWineTier[1] || type.field_wineTier === self.activeWineTier[2];
              case 4:
                console.log("case 4")
                return type.field_wineTier === self.activeWineTier[0] || type.field_wineTier === self.activeWineTier[2] || type.field_wineTier === self.activeWineTier[1] || type.field_wineTier === self.activeWineTier[3];
            }
          } else if (self.activeWineTier.length >= 1 && self.activeWine.length < 1) {
            console.log('third')
            switch (self.activeWineTier.length) {
              case 0:
                break;
              case 1:
                return type.field_wineTier === self.activeWineTier[0];
              case 2:
                console.log("case 2")
                return type.field_wineTier === self.activeWineTier[0] || type.field_wineTier === self.activeWineTier[1];
              case 3:
                console.log("case 3")
                return type.field_wineTier === self.activeWineTier[0] || type.field_wineTier === self.activeWineTier[1] || type.field_wineTier === self.activeWineTier[2];
              case 4:
                console.log("case 4")
                return type.field_wineTier === self.activeWineTier[0] || type.field_wineTier === self.activeWineTier[2] || type.field_wineTier === self.activeWineTier[1] || type.field_wineTier === self.activeWineTier[3];
            }

          } else {
            console.log('fith')
            // console.log(type.field_currentVintage);
            // console.log(type.field_currentVintage_wfeydybl);

            return self.tech;
            // type.field_currentVintage_wfeydybl === "1";
          }

          // && type.field_wineTier == "Estate"
        }
      })
    },
    specials: function () {

      if (this.products.products.length) {
        return this.products.products.filter(function (type) {
          console.log(type.wineCode);

          return type.wineCode.includes("L") || type.wineCode.includes("Y");
        })
      }


    },

  },
  methods: {

    titleDisplay: function (value) {
      if (this.display.some(e => e.field_wineTier === value)) {
        return true;
      }
      else {
        return false;
      }
    },
    promotionDisplay: function (sku){

      let currentDate = new Date().toLocaleDateString();
      if(this.promos.entries) {

        for (let i = 0; i < this.promos.entries.length; i++) {
          let displayUntil = this.promos.entries[i].displayUntil;
          console.log(currentDate);
          console.log(displayUntil);

          if (this.promos.entries[i].promoSku === sku.field_sku && currentDate <= displayUntil.substring(0, currentDate.length)) {
            sku.field_promoMessage_xyvkrwsy = this.promos.entries[i].promoMessage;
            sku.field_promoType_qkkxzmgw = this.promos.entries[i].promoType;
            console.log("great success")
            return true;
          }
        }
      }

      return false;

    },
   
    matchWine: function (sku) {

      return this.tech.filter(function (type) {
        return type.field_sku === sku;
      })
    },
    noMatch: function (sku) {
      console.log(sku);
      var log = this.matchWine(sku);
      if (log.length > 0) {
        return true;
      } else {
        return false;
      }
  
    },

    determineClass: function (value, value2, value3, value4 , value5) {
      let currentDate = new Date().toLocaleDateString();
      switch (value) {
        case "Riesling":

          console.log('unlucky');

          console.log(value, value2, value3, value4, value5)

          // if(this.promos.entries){
          //   console.log('lucky');
          // }

          if (this.promos.entries) { //make sure its member pre-release
            // console.log(this.promos.entries.length);
            for (let i = 0; i < this.promos.entries.length; i++) {
              console.log('lucky');


              if (this.promos.entries[i].promoSku === value3 && this.promos.entries[i].promoType === 'memberPreRelease') {
                return ('hidden')

              }

            }
          }


          if (!value2){
            // console.log(value5)
            if (value5.toUpperCase().includes("CS2GO")) {
              console.log(value5 + " cs2go")
              return ('order-3')
            } else if (value5.toUpperCase().includes("DRY")) {
              if (value4.includes("375")) {
                console.log(value5 + " 375")
                return ('order-2')
              } else {
                console.log(value5 + "DRY")
                return ('order-1')
              }


            } else if(value5.toUpperCase().includes("SELECT LATE HARVEST") || value5.toUpperCase().includes("ICEWINE")) {
              console.log(value5 + "ORDER 4")
              return ('order-[20]')
            } else {
              return ('order-4')
            }
          } else {
            console.log(value5 + "ORDER 17")
            return ('order-[17]')
          }

        case "Chardonnay":

          if (this.promos.entries) { //make sure its member pre-release
            // console.log(this.promos.entries.length);
            for (let i = 0; i < this.promos.entries.length; i++) {
              console.log('lucky');


              if (this.promos.entries[i].promoSku === value3 && this.promos.entries[i].promoType === 'memberPreRelease') {
                return ('hidden')

              }

            }
          }

          if (!value2) {
            if (value4.includes("375")) {

              return ('order-6')
            }else {
              return ('order-5')
            }
          } else {
            return ('order-[18]')
          }

        case "Pinot Gris":
          if (this.promos.entries) { //make sure its member pre-release
            // console.log(this.promos.entries.length);
            for (let i = 0; i < this.promos.entries.length; i++) {
              console.log('lucky');


              if (this.promos.entries[i].promoSku === value3 && this.promos.entries[i].promoType === 'memberPreRelease') {
                return ('hidden')

              }

            }
          }
          return ('order-7')

        case "Gewürztraminer":
          if (this.promos.entries) { //make sure its member pre-release
            // console.log(this.promos.entries.length);
            for (let i = 0; i < this.promos.entries.length; i++) {
              console.log('lucky');


              if (this.promos.entries[i].promoSku === value3 && this.promos.entries[i].promoType === 'memberPreRelease') {
                return ('hidden')

              }

            }
          }
          return ('order-8')

        case "Rosé":
          if (this.promos.entries) { //make sure its member pre-release
            // console.log(this.promos.entries.length);
            for (let i = 0; i < this.promos.entries.length; i++) {
              console.log('lucky');


              if (this.promos.entries[i].promoSku === value3 && this.promos.entries[i].promoType === 'memberPreRelease') {
                return ('hidden')

              }

            }
          }

          if (!value2) {
            if (value5.toUpperCase().includes("CS2GO")) {
              return ('order-11')
            }else if (value4.includes("375")) {
              return ('order-10')
            } else {
              return ('order-9')
            }
          } else {
            return ('order-[19]')

          }



        case "Gamay":
          if(this.promos.entries) { //make sure its member pre-release
            // console.log(this.promos.entries.length);
            for (let i = 0; i < this.promos.entries.length; i++) {
              console.log('lucky');


              if (this.promos.entries[i].promoSku === value3  && this.promos.entries[i].promoType === 'memberPreRelease') {
                return ('hidden')

              }

            }
          }
          if (!value2) {
            return ('order-12')
          }else {
            return ('order-[19]')
          }

        case "Pinot Noir":
          if(this.promos.entries) { //make sure its member pre-release
            // console.log(this.promos.entries.length);
            for (let i = 0; i < this.promos.entries.length; i++) {
              console.log('lucky');


              if (this.promos.entries[i].promoSku === value3  && this.promos.entries[i].promoType === 'memberPreRelease') {
                return ('hidden')

              }

            }
          }
          return ('order-[13]')

        case "Cabernet Franc":
          if(this.promos.entries) { //make sure its member pre-release
            // console.log(this.promos.entries.length);
            for (let i = 0; i < this.promos.entries.length; i++) {
              console.log('lucky');


              if (this.promos.entries[i].promoSku === value3 && this.promos.entries[i].promoType === 'memberPreRelease') {
                return ('hidden')

              }

            }
          }
          if(value5.toUpperCase().includes("CS2GO") ) {
            return ('order-[17]')
          }else if (value4.includes("200")) {
            return ('order-[16]')
          }else if (value4.includes("375")) {
        return ('order-[15]')
          } else {
          return ('order-[14]')
        }

        // case "Cabernet Franc":
        //   return ('order-8')
        default:
          if(this.promos.entries) { //make sure its member pre-release
            // console.log(this.promos.entries.length);
            for (let i = 0; i < this.promos.entries.length; i++) {
              console.log('lucky');


              if (this.promos.entries[i].promoSku === value3  && this.promos.entries[i].promoType === 'memberPreRelease') {
                return ('hidden')

              }

            }
          }
          return ('default')  




      }
    },
    selection: function (value) {
      this.redSpecialCase = false;
      if (value === 'White') {
        console.log("WHITE49")
        if (this.isActive === true) {
          this.isActive = false;
          console.log(this.$refs.child)
          for (let i = 0; i < this.$refs.child.length; i++) {
            let element = this.$refs.child[i].$el;
            if (element.classList.contains('active')) {
              this.$refs.child[i].toggle();
            }
          }


          console.log(value);
          this.activeType = '';
          this.activeWine.splice(0, this.activeWine.length);
        } else {
          console.log("WHITE50")
          for (let i = 0; i < this.$refs.parent.length; i++) {
            let element = this.$refs.parent[i].$el;
            if (element.classList.contains('active')) {
              this.$refs.parent[i].toggle();
            }
          }  for (let i = 0; i < this.$refs.tier.length; i++) {
            let tiers = this.$refs.tier[i].$el;
            if (tiers.classList.contains('active')) {
              this.$refs.tier[i].toggle();
            }
          }
          this.activeWineTier.splice(0, this.activeWineTier.length);
          this.isActive = true;
          this.activeType = value;
          // console.log(this.$refs.child[0]);


        }
      } else if (value === 'Red') {
        if (this.isActiveRed === true) {
          this.isActiveRed = false;
          console.log(value);
          this.activeType = '';
          for (let i = 0; i < this.$refs.child.length; i++) {
            let element = this.$refs.child[i].$el;
            if (element.classList.contains('active')) {
              this.$refs.child[i].toggle();
            }
          }

        } else {
          for (let i = 0; i < this.$refs.parent.length; i++) {
            let element = this.$refs.parent[i].$el;
            if (element.classList.contains('active')) {
              this.$refs.parent[i].toggle();
            }
          }
          for (let i = 0; i < this.$refs.tier.length; i++) {
            let tiers = this.$refs.tier[i].$el;
            if (tiers.classList.contains('active')) {
              this.$refs.tier[i].toggle();
            }
          }
          this.activeWineTier.splice(0, this.activeWineTier.length);
          this.isActiveRed = true;
          this.activeType = value;

        }
      } else if (value === 'Rosé' || value === 'Sweet' || value === "Sparkling") {
        if (this.activeType === value) {
          this.activeType = '';
        } else {
          for (let i = 0; i < this.$refs.parent.length; i++) {
            let element = this.$refs.parent[i].$el;
            if (element.classList.contains('active')) {
              this.$refs.parent[i].toggle();
            }
          }
          this.activeType = value;
          for (let i = 0; i < this.$refs.child.length; i++) {
            let element = this.$refs.child[i].$el;
            if (element.classList.contains('active')) {
              this.$refs.child[i].toggle();
            }
          } for (let i = 0; i < this.$refs.tier.length; i++) {
            let tiers = this.$refs.tier[i].$el;
            if (tiers.classList.contains('active')) {
              this.$refs.tier[i].toggle();
            }
          }
          this.activeWineTier.splice(0, this.activeWineTier.length);
          this.activeWine.splice(0, this.activeWine.length);
        }
      }
      else if (value === 'Regional' || value === 'Dolomite' || value === 'Estate' || value === 'Proprietary') {
        if (!this.activeWineTier.includes(value)) {
          this.activeWineTier.push(value);
          for (let i = 0; i < this.$refs.parent.length; i++) {
            let parents = this.$refs.parent[i].$el;
            if (parents.classList.contains('active')) {
              this.$refs.parent[i].toggle();
            }
          }
        } else {
          let index = this.activeWineTier.indexOf(value);
          this.$delete(this.activeWineTier, index);
        }
        this.activeType = '';
        this.activeWine.splice(0, this.activeWine.length);
        }
    },
    reset: function () {
      console.log("reset function")
      for (let i = 0; i < this.$refs.parent.length; i++) {
      let parents = this.$refs.parent[i].$el;
      if (parents.classList.contains('active')) {
        this.$refs.parent[i].toggle();
      }
      }
      for (let i = 0; i < this.$refs.child.length; i++) {
        let children = this.$refs.child[i].$el;
        if (children.classList.contains('active')) {
          this.$refs.child[i].toggle();
        }
      }
      for (let i = 0; i < this.$refs.tier.length; i++) {
        let tiers = this.$refs.tier[i].$el;
        if (tiers.classList.contains('active')) {
          this.$refs.tier[i].toggle();
        }
      }
      this.activeWineTier.splice(0, this.activeWineTier.length);
      this.activeWine.splice(0, this.activeWine.length);
      this.activeType = '';
      this.tierSubsection = '';
    },
    // resetTier: function () {
    //   console.log("reset tier function")
    //   if (this.$refs.parent.length > 0) {
    //     for (let i = 0; i < this.$refs.parent.length; i++) {
    //       let parents = this.$refs.parent[i].$el;
    //       if (parents.classList.contains('active')) {
    //         this.$refs.parent[i].toggle();
    //       }
    //     }
    //     for (let i = 0; i < this.$refs.child.length; i++) {
    //       let children = this.$refs.child[i].$el;
    //       if (children.classList.contains('active')) {
    //         this.$refs.child[i].toggle();
    //       }
    //     }
    //   }
    //   this.activeWine.splice(0, this.activeWine.length);
    //   this.activeType = '';
    // },
    selectionChild: function (value) {
      this.redSpecialCase = false;
      console.log(value);
      if (!this.activeWine.includes(value)) {
              this.activeWine.push(value);
      } else {
        let index = this.activeWine.indexOf(value);
        this.$delete(this.activeWine, index);
      }
    },
    tierCheckbox: function (value) {
      this.activeWine.splice(0, this.activeWine.length);
      this.activeType = '';
     
      switch (value) {
        case "Regional":
          console.log('Regional')
          this.$refs.tier[0].toggle();
          break;

          case "Regional-subsection":
          console.log('Regional Subsection')
            if (this.tierSubsection === 'Regional') {
              this.tierSubsection = ''
            }else(
                this.tierSubsection = 'Regional'
            )

          this.$refs.tier[0].toggle();
          break;
      
        case "Dolomite":
          console.log('Dolomite')
          this.$refs.tier[1].toggle();
          break;

        case "Dolomite-subsection":
          console.log('Dolomite Subsection')
          if (this.tierSubsection === 'Dolomite') {
            this.tierSubsection = ''
          }else(
              this.tierSubsection = 'Dolomite'
          )
          this.$refs.tier[1].toggle();
          break;


        case "Estate":
          console.log('Estate')
          this.$refs.tier[2].toggle();
          break;

        case "Estate-subsection":
          console.log('Estate Subsection')
          if (this.tierSubsection === 'Estate') {
            this.tierSubsection = ''
          }else(
              this.tierSubsection = 'Estate'
          )

          this.$refs.tier[2].toggle();
          break;


        case "Proprietary":
          console.log('Proprietary')
          this.$refs.tier[3].toggle();
          break;


        case "Proprietary-subsection":
          if (this.tierSubsection === 'Proprietary') {
            this.tierSubsection = ''
          }else(
              this.tierSubsection = 'Proprietary'
          )
          console.log('Proprietary Subsection')
          this.$refs.tier[3].toggle();
          break;




        default:
          console.log('yeehaw');
          console.log(value);
          break;
      


      }
      
      
    }
    },
  
     created() {
       
    this.storeWine = JSON.parse(JSON.stringify(this.store));
       this.techWine = JSON.parse(JSON.stringify(this.tech));
    // this.products = JSON.parse(JSON.stringify(this.products));
    // console.log(this.techWine);
    // console.log(this.storeWine);
  },
  mounted() {
    console.log(this.$refs)
    console.log(this.$refs.child)
    console.log(this.$refs.parent)
    console.log(this.$refs.tier)
    console.log(this.$route.query.t)


    if (this.$route.query.t && !this.$route.query.v) {

      switch (this.$route.query.t) {
        case "White":
          console.log('White')
          this.$refs.parent[0].toggle();
          // this.selection(this.$route.query.t);
          break;

        case "Red":
          console.log('Red')
          this.$refs.parent[2].toggle();
          // this.selection(this.$route.query.t);
          break;

        case "Rosé":
          console.log('Rose')
          this.$refs.parent[1].toggle();
          // this.selection(this.$route.query.t);
          break;

        case "Sparkling":
          console.log('Sparking')
          this.$refs.parent[3].toggle();
          // this.selection(this.$route.query.t);
          break;

        case "Sweet":
          console.log('Sweet')
          this.$refs.parent[4].toggle();
          // this.selection(this.$route.query.t);
          break;

        default:
          console.log('yeehaw');
          console.log(this.$route.query.t);
          break;





      }
    } else if (this.$route.query.v) {
      if (this.$route.query.t === "White") {

        switch (this.$route.query.v) {
          case "Riesling":
            console.log('Riesling')
            this.$refs.parent[0].toggle();
            this.$refs.child[0].toggle();
            // this.selection(this.$route.query.t);
            break;

          case "Chardonnay":
            console.log('Chardonnay')
            this.$refs.parent[0].toggle();
            this.$refs.child[1].toggle();
            // this.selection(this.$route.query.t);
            break;
          case "Pinot Gris":
            console.log('Pinot Gris')
            this.$refs.parent[0].toggle();
            this.$refs.child[2].toggle();
            // this.selection(this.$route.query.t);
            break;
          case "Gewürztraminer":
            console.log('Gewürztraminer')
            this.$refs.parent[0].toggle();
            this.$refs.child[3].toggle();
            // this.selection(this.$route.query.t);
            break;
        }
      } else if(this.$route.query.t === "Red") {

        switch (this.$route.query.v) {
          case "Pinot Noir":
            console.log('Pinot Noir')
            this.$refs.parent[2].toggle();
            this.$refs.child[5].toggle();
            // this.selection(this.$route.query.t);
            break;

          case "Cabernet Franc":
            console.log('Cabernet Franc')
            this.$refs.parent[2].toggle();
            this.$refs.child[6].toggle();
            // this.selection(this.$route.query.t);
            break;
          case "Gamay":
            console.log('Gamay')
            this.$refs.parent[2].toggle();
            this.$refs.child[4].toggle();
            // this.selection(this.$route.query.t);
            break;
          case "La Penna":
            console.log('La Penna')
            this.$refs.parent[2].toggle();
            this.$refs.child[7].toggle();
            // this.selection(this.$route.query.t);
            break;
        }
      }
    }
  },


}
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease;
}

.fade-enter,
.fade-leave-to{
  opacity: 0;
}

.REDEEMTOURWF {
  order: 2;
}
.REDEEMVH50 {
  order: 3 ;
}
.REDEEMOB50 {
  order: 1;
}
.REDEEMESTATE250 {
  order: 5;
}
.REDEEMCHAR50 {
  order: 4;
}
.REDEEMBECK50 {
  order: 6;
}
</style>
